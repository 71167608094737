import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Dialog from '@mui/material/Dialog';
import { image, content, subscribeContent } from './member-dialog.module.css';
import { styled } from '@mui/material/styles';
import MuiDialogContent from '@mui/material/DialogContent';
import SubscriptionForm from './layout/SubscriptionForm';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const PREFIX = 'MemberDialog';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  [`& .${classes.root}`]: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
}));

// const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
//   root: {
//     margin: 0,
//     textAlign: 'center',
//     padding: theme.spacing(1),
//   },
// }));

// eslint-disable-next-line react/display-name
const MemberDialog = ({ memberDialogOpen, setMemberDialogOpen }) => {
  // const handleClickOpen = () => {
  //   setMemberDialogOpen(true);
  // };
  // const url = 'https://cafeausoul.us17.list-manage.com/subscribe/post?u=73fcb5626d722c9209f2890d9&id=89b4971017';
  const url =
    'https://cafeausoul.us17.list-manage.com/subscribe/post?u=73fcb5626d722c9209f2890d9&amp;id=89b4971017&amp;f_id=00dff7e2f0';
  const handleClose = () => {
    setMemberDialogOpen(false);
  };
  return (
    <StyledDialog onClose={handleClose} open={memberDialogOpen}>
      <DialogContent dividers>
        <div className={image}>
          <StaticImage
            width={400}
            quality={100}
            placeholder={'none'}
            layout={'constrained'}
            src="../images/books.png"
            alt="Books by Kari Hohne"
          />
        </div>
        <h3 style={{ textAlign: 'center' }}>DOWNLOAD FREE!</h3>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p className={content}>
            Join our VIP Club to receive our newsletter and enjoy free access to e-books, articles, mp3s, and online
            courses about dream interpretation, nature&apos;s wisdom, astrology and more!
          </p>
          <div className={subscribeContent}>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <SubscriptionForm
                  url={url}
                  status={status}
                  message={message}
                  onValidated={(formData) => {
                    subscribe(formData);
                  }}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default MemberDialog;
